<template>
	<el-select
		v-model="activeIds" 
		style="width: 300px;"
		placeholder="请选择"
		suffix-icon="Avatar"
		ref="select"
		class="lida-select"
		filterable
		collapse-tags
		multiple
		>
	    <el-option
	      v-for="item in list"
	      :key="item[value]"
	      :label="setLabel(item)"
	      :value="item[value]"
	    >
	    </el-option>
	</el-select>
</template>

<script>
	export default{
		data(){
			return{
				options:[],
				activeIds:[],
			}
		},
		props:{
			list:{
				type:Array,
				default:() => {
					return [];
				}
			},
			ids:{
				type:Array,
				default:() => {
					return [];
				}
			},
			value:{
				type:String,
				default:'id',
			},
			label:{
				type:String,
				default:'',
			}
		},
		methods:{
			showMore(e){
				e = e || event;
				e.stopPropagation();
				this.$emit('moreClick'); //点击更多的事件
			},
			setLabel(item){
				return eval(this.getLabel)
			}
		},
		computed:{
			getLabel(){
				return this.label && this.label.indexOf('item.') !== -1 ?
				this.label : 'item.' + this.label;
			}
		},
		created(){
			//更改节点
			this.$nextTick(() => {
				const fn = this.showMore;
				const vapp = Vue.createApp({
					render() {
						return Vue.h(
							'p', {
								style: {
									'font-size': '18px',
									display: 'inline-block',
									cursor: 'pointer',
									height:'100%',
									width:'30px',
									'text-align':'center',
								},
								class:['iconfont','icon-xianxingtubiaozhizuomoban-25'],
								onClick: fn,
							},
						)
					}
				})
				const parent = document.createElement('div');
				const doms = vapp.mount(parent);
				this.$refs.select.$refs.selectWrapper.querySelector('.el-input__suffix').innerHTML = '';
				this.$refs.select.$refs.selectWrapper.querySelector('.el-input__suffix').appendChild(doms.$el);
				
			})
		},
		watch:{
			ids(a,b){
				if(JSON.stringify(a) !== JSON.stringify(b)){
					this.activeIds = a;
				}
			},
			activeIds(v){
				var data = this.list.filter(a => v.some(n => n === a[this.value]));
				this.$emit('changeGoods',v,data);
			}
		}
	}
</script>

<style scoped lang="less">
	.lida-select{
		/deep/ .el-input--suffix{
			display: flex;
			align-items: center;
			border: 1px solid #d5dae0 !important;
			border-radius: 4px;
			
			.el-input__suffix{
				position: inherit;
				height: 32px;
				line-height: 32px;
				position: relative;
				pointer-events:inherit;
			}
		}
		/deep/ .el-input__inner, .el-textarea__inner, .el-button--default{
			flex: 1;
			width: auto;
			border: 1px solid #ffffff !important;
			padding-right: 0;
		}
		/deep/ .iconfont:hover{
			color: @dh-color;
		}
	}
	
</style>
